<script lang="ts" setup>
import type { MediaImage } from '~~/types/storyblok/media'
const props = defineProps<{
  blok: MediaImage | undefined
  width?: number
  height?: number
  withOverlay?: boolean | false
  overlayFromBottom?: boolean | false
  objectCover?: boolean | false
  grayscale?: boolean | false
  aspect?: string | 'none'
  alt?: string | 'Illustrative image'
  isHero?: boolean | false
}>()

const aspectClass = computed(() => `aspect-${props.aspect}`)

interface FilterOptions {
  grayscale?: string
  focal?: string
}

const filterOptions = computed((): FilterOptions => {
  const options: FilterOptions = {}
  if (props.grayscale) {
    options.grayscale = ''
  }
  if (props?.blok?.asset?.focus) {
    options.focal = props.blok.asset.focus
  }
  return options
})
</script>

<template lang="pug">
div(
  v-editable="blok"
  v-if="props?.blok?.asset"
  class="media-item media-image"
  :class="{ 'is-hero': isHero }"
)
  div(class="media flex justify-center items-center")
    div(
      class="media__wrapper"
      :class="[objectCover ? 'image-with-aspect relative w-full' : '', withOverlay ? 'relative' : '']"
    )
      div(class="image w-full" :class="aspectClass")
        NuxtImg(
          provider="storyblok"
          :width="props.blok?.width || props.width"
          :height="props.height"
          quality="80"
          :src="props.blok.asset.filename"
          :modifiers="{ smart: true, filters: filterOptions }"
          :alt="props.blok.asset?.alt || props?.alt"
          :class="objectCover ? 'absolute inset-0 w-full h-full object-cover' : ''"
        )
      div(
        v-if="withOverlay && !overlayFromBottom"
        class="absolute inset-0 bg-image-overlay w-full h-full z-10 mix-blend-multiply"
      )
      div(
        v-if="withOverlay && overlayFromBottom"
        class="absolute inset-0 bg-image-overlay-reverse w-full h-full z-10 mix-blend-multiply"
      )
</template>

<style lang="postcss" scoped>
.image-with-aspect {
  .image {
    &.aspect-insight-card {
      @apply pt-[133%];
    }

    &.aspect-team-member {
      @apply pt-[120%];
      img {
        @apply object-top;
      }
    }

    &.aspect-square {
      @apply pt-[100%];
    }

    &.aspect-widescreen {
      @apply pt-[56.25%];
    }

    &.aspect-article-hero {
      @apply pt-[44%];
    }
  }
}

.media-image.is-hero {
  @apply h-full;

  .media,
  .media__wrapper {
    @apply h-full;

    .image {
      @apply pt-[100%] md:pt-0 md:h-full;
    }
  }
}
</style>
